// 特定の文字列を強制的に書き換え
window.addEventListener("DOMContentLoaded", function () {
  if (window.location.href.includes("/en")) {
    let content = document.head.innerHTML;
    content = content
      .replace(
        /東京青山のAGOセラピストスクールでは、グローバルに活躍できる女性セラピストの輩出を目指しています。/g,
        "AGO Therapist School in Aoyama, Tokyo, aims to produce female therapists who can work globally."
      )
      .replace(/AGOセラピストスクール/g, "AGO Therapist School");
    document.head.innerHTML = content;
  }
});
