const triggers = document.querySelectorAll(".p-top__parallax");
const links = document.querySelectorAll(".l-header-nav-pc__list-link");

// オプション設定
const options = {
  root: null, // ビューポートを基準とする
  rootMargin: "0px",
  threshold: 0, // 交差した瞬間にコールバックを実行
};

const observer = new IntersectionObserver(colorChange, options);

triggers.forEach((trigger) => {
  observer.observe(trigger);
});

// IntersectionObserverのコールバック関数
function colorChange(entries, observer) {
  entries.forEach((entry) => {
    // 監視対象が画面の上部に触れているか
    if (entry.isIntersecting) {
      links.forEach((link) => link.classList.add("js-add-nav"));
    } else {
      links.forEach((link) => link.classList.remove("js-add-nav"));
    }
  });
}
