import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

let contactBtn = document.getElementById("contactBtn");
let contactList = document.getElementById("contactList");
let plus = document.querySelector(".c-contact__plus");

contactBtn.addEventListener("click", () => {
  plus.classList.toggle("js-add-contact-border");

  // アコーディオン
  if (contactList.style.height === "0px" || contactList.style.height === "") {
    // 開く
    gsap.to(contactList, {
      height: "auto",
      opacity: 1,
      duration: 0.6,
      ease: "power3.inOut",
    });
  } else {
    // 閉じる
    gsap.to(contactList, {
      height: 0,
      opacity: 0,
      duration: 0.6,
      ease: "power3.inOut",
    });
  }
});
